<template>
  <div class="sidebar-layout">
    <main>
      <header class="site-header" ref="header">
        <slot name="header"></slot>
      </header>
      <aside class="sidebar" ref="sidebar">
        <slot name="sidebar">
          <ImagesBoard :grid="true" :rows="4" :columns="2"/>
        </slot>
      </aside>
      <article class="page-content" ref="content">
        <slot name="content"></slot>
      </article>
    </main>
    <NavrightHome/>
  </div>
</template>

<script>
import NavrightHome from "@/components/NavrightHome";
import ImagesBoard from "@/components/ImagesBoard";

export default {
  name: "LeftSidebarLayout",
  components: {
    NavrightHome,
    ImagesBoard
  },
  mounted() {
    this.adjustSize();
  },
  methods: {
    adjustSize() {
      const headerHeight = this.$refs.header.offsetHeight;
      this.$refs.sidebar.style.top = `${headerHeight}px`;
      this.$refs.content.style.height = `calc(100vh - ${headerHeight}px)`;
      this.$refs.content.style.top = `${headerHeight}px`;
    }
  }
};
</script>

<style lang="scss" scoped>
main {
  width: 100%;
  height: 100%;

  .site-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 24.47vw;
    height: 100vh;
    z-index: 11;
  }

  .page-content {
    position: absolute;
    top: 0;
    left: 24.47vw;
    width: calc(75.53vw - 40px);
  }
}
</style>
