<template>
  <div id="plan-your-trip">
    <LeftSidebarLayout>
      <template v-slot:header>
        <b-navbar class="other" variant="faded" type="light" toggleable="lg">
          <b-navbar-toggle target="nav_text_collapse"></b-navbar-toggle>
          <b-navbar-brand :to="'/'">
            <img :src="logoHome" alt="Mongolia" class="mr-4">
            <span class="text-brand">PLAN YOUR TRIP</span>
          </b-navbar-brand>
          <b-collapse is-nav id="nav_text_collapse">
            <b-navbar-nav class="mc">
              <b-nav-item :to="{ name: 'getting-to-mongolia' }">
                <span>Getting to Mongolia</span>
              </b-nav-item>
              <b-nav-item :to="{ name: 'travel-around' }">
                <span>Travel Around</span>
              </b-nav-item>
              <b-nav-item :to="{ name: 'custom-culture' }">
                <span>Custom &amp; Culture</span>
              </b-nav-item>
              <b-nav-item :to="{ name: 'practical-information' }">
                <span>Practical Information</span>
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </template>
      <template v-slot:content>
        <router-view/>
      </template>
    </LeftSidebarLayout>
  </div>
</template>

<script>
import LeftSidebarLayout from "@/layouts/LeftSidebarLayout";
import LogoHome from "../../assets/img/logo-mongolia-home-2.png";

export default {
  name: "PlanYourTrip",
  components: {
    LeftSidebarLayout
  },
  data() {
    return {
      logoHome: LogoHome
    };
  },
  methods: {
    onImagesBoardCalculateSize(size) {
      this.$refs.ig.style.width = size.width + "px";
    }
  }
};
</script>

<style lang="scss" scoped>
#plan-your-trip {
  .other {
    background-color: #ffffff;
    align-items: center;

    .text-brand {
      margin-top: 20px;
      color: #e13036;
      font-size: 30px;
      font-weight: bold;
    }
  }

  .fixed-top {
    z-index: 10;
  }

  .mc {
    margin: auto;
    .nav-item {
      padding-left: 25px;
      padding-right: 25px;
      .nav-link {
        color: #e13036;
        padding-top: 0px;
        &:hover {
          color: red;
        }

        &.router-link-exact-active {
          span {
            color: #e13036;
            padding-bottom: 5px;
            border-bottom: 3px solid #e13036;
          }
        }
      }
    }
  }

  .content {
    display: flex;
    .ig {
      position: fixed;
      width: 24.46%;
      height: calc(100vh - 102px);
      box-sizing: content-box;
      padding-top: 102px;
      // background-color: #999;
      overflow: hidden;
    }
    .content-detail {
      padding-top: 102px;
      padding-left: 24.46%;
      width: 100%;
      height: 100vh;
      // background-color: aqua;
    }
  }

  @media only screen and (min-width: 1920px) {
    .mc {
      .nav-item {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }

  // header {
  // 	display: flex;
  // 	justify-content: space-between;
  // 	align-items: center;
  // }
}
</style>